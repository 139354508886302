import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function KarmaCatalogList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false, label: "Select" },
        { key: "karma_name", sortable: false, label: "Karma Name" },
        { key: "karma_category", sortable: false, label: "Karma Category" },
        { key: "sub_karma_category", sortable: false, label: "Sub Karma Category" },
        { key: "karma_point", sortable: false, label: "Points" },
        { key: "status", sortable: false, label: "Status" },
        { key: "actions" }
    ];
    const perPage = ref(10);
    const totalKarmaCatalogList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const karmaCatalogueMetaData = ref([]);
    const karmaCatalogStatusOption = ref([]);
    const categoryFilter = ref(null);
    const parentCategoryOption = ref([]);
    const auditorFilter = ref(null);
    const applyPermissionFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalKarmaCatalogList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch(
        [
            currentPage,
            perPage,
            searchQuery,
            statusFilter,
            categoryFilter,
            auditorFilter,
            applyPermissionFilter
        ],
        () => {
            refetchData();
        }
    );

    const fetchKarmaCatalogList = (ctx, callback) => {
        store
            .dispatch("app-karma-catalogue/fetchKarmaCatalogue", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                categoryFilter: categoryFilter.value,
                auditorFilter: auditorFilter.value,
                applyPermissionFilter: applyPermissionFilter.value,
            })
            .then(response => {
                const karma_catalog = response.data.data;
                const { total } = response.data.meta;
                karmaCatalogStatusOption.value = response.data.status;
                parentCategoryOption.value = response.data.parent_category_option;

                callback(karma_catalog);
                totalKarmaCatalogList.value = total;
                karmaCatalogueMetaData.value = karma_catalog;
            })
            .catch(error => {
                console.error(error);
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching karma catalog",
                        icon: "AlertTriangleIcon",
                        variant: "danger"
                    }
                });
            });
    };

    const resolveUserStatusVariant = status => {
        if (status == false) return "danger";
        if (status == true) return "success";
        return "primary";
    };

    return {
        fetchKarmaCatalogList,
        tableColumns,
        perPage,
        currentPage,
        totalKarmaCatalogList,
        karmaCatalogueMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        resolveUserStatusVariant,
        karmaCatalogStatusOption,
        statusFilter,
        categoryFilter,
        parentCategoryOption,
        auditorFilter,
        applyPermissionFilter
    };
}
